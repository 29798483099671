/* header css */

header.top_header {
  background-image: url(../../img/headerbg.png);
  /* padding: 6px 0; */
  background-size: cover;
  background-position: center;
}

header.top_header a.navbar-brand {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #FFFFFF !important;
}

header.top_header .nav-link i {
  color: #777391;
  background: #FFFFFF;
  font-size: 20px;
  border-radius: 26px;
  padding: 12px;
  position: relative;
}

header.top_header ul.navbar-nav.ml-auto {
  margin-left: auto;
}

.down_btn button {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 12px 16px;
  display: flex;
  flex-flow: row;
  padding-right: 44px;
  border: 2px solid transparent;
  /* margin-left: 20px; */
  width: 100%;
  min-width: 206px;

}

.down_btn button span {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #2E294E;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 134px !important;
  width: 100%; */
  /* padding-right: 23px; */
  display: block;
  text-align: left;
}

.dropdown.down_btn button img {
  margin-right: 6px;
}

header.top_header .navbar-nav.ml-auto {
  display: flex;
  align-items: center;
}

header.top_header .dropdown-toggle::after {
  background-image: url(../../img/Vector.png);
  border: none;
  width: 22px;
  height: 22px;
  background-size: 17px;
  background-repeat: no-repeat;
  top: 59%;
  left: 89%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.css-1u9des2-indicatorSeparator {
  display: none;
}

header.top_header .down_btn button:hover {
  /* color: var(--bs-btn-hover-color); */
  background-color: #540c2c;
  border-color: #fff;
  /* border: none !important; */
  color: #fff;
  border-radius: 8px;
  border: 2px solid;

}


header.top_header .down_btn button:hover>span {
  color: #fff;
}

a.nav-link.notifiy_icon span {
  background: #EB5548;
  height: 12px;
  width: 12px;
  position: absolute;
  left: 64%;
  top: 40%;
  transform: translate(-50%, -50%);
  border-radius: 37px;
  border: 2px solid #fff;
}

a.nav-link.notifiy_icon {
  position: relative;
}

.users-container {
  padding: 20px;
}

.filters {
  margin-bottom: 20px;
}

.filters label {
  display: block;
  margin: 5px 0;
}

.user-list {
  margin-top: 20px;
}