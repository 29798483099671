section.Landingpage {
    background: #f7f4ed;
    height: 100vh;
    padding: 3% 0;
}



.Landing_div h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #957137;
    font-family: 'Plus Jakarta Sans', sans-serif;
    text-align: center;
    margin-bottom: 50px;
    /* margin-top: 10px; */

}

.Landing_div svg {
    width: 100%;
    max-width: 35px;
    margin-bottom: 15px;
    height: 100%;
    max-height: 40px;
    transition: all 0.3s ease-in-out;
    /* margin: 0 auto; */
}

.Landing_div {
    /* margin-bottom: 30px; */
    border-radius: 12px;
    height: 100%;
    width: 100%;
    max-width: 214px;
    text-align: center;
    padding: 28px 11px;
    cursor: pointer;
    max-height: 146px;
    /* margin: 10px 10px; */
    background: #FFFFFF;
    border: 1px solid #E7E6EE;
    box-shadow: 0px 4px 2px -2px rgba(27, 46, 94, 0.02);
    border-radius: 16px;
    display: block;
    transition: all 0.3s ease-in-out;
}

.Landing_div:hover {
    border-color: #480b26 !important;
    transition: all 0.3s ease-in-out;
}

.Landing_div:hover>svg path,
.Landing_div:hover>h1 {
    fill: #480b26 !important;
    color: #480b26;
    transition: all 0.3s ease-in-out;
}

.Landing_page_list {
    display: flex;
    flex-flow: wrap;
    /* justify-content: center; */
    /* margin-bottom: 10px; */
}


section.Landingpage .Heding_text h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #777391;
}

section.Landingpage .Heding_text h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #2E294E;
}

.Cartere_canvas  .Register_user_input .form-switch .form-check-input {
    width: 5em;
    height: 2em;
    border-radius: 30px;
}

.plusimg{
    width: 26px;
    opacity: 0.7;
}




.calender-icon-div img {
    width: 75%;
    max-width: 50px;
    position: relative;
    height: 50px;
}

.calender-icon-div {
    position: relative;
    margin-right: -10px;
}



.calender-icon-div .Calender_div {
    min-width: 50px;
    max-width: 50px;
    position: absolute;
    height: 50px;
    top: 0;
    left: -6px;
    transform: translate(-0%, -0%);
    opacity: 0;
}


.New_section  .calender-icon-div .Calender_div input.form-control {
    min-width: 50px;
}

@media (max-width: 767px) {
    .calender-icon-div .Calender_div {
      min-width: 25px;
      max-width: 24px;
      height: 40px;
      /* opacity: 0; */
    }
    .calender-icon-div img {
      max-width: 30px;
      height: 30px;
      margin: 0 auto;
    }

    .calender-icon-div {
        margin-right: 0;
        padding-left: 15px;
    }

    a.Landing_div {
        margin: 0 auto;
    }
  }

  .Remove_from img.img-firud.inv-img {
    background: #480b26;
    padding: 13px 12px;
    border-radius: 79px;
}

