body .container-fluid {
  padding-left: 20px;
  padding-right: 20px;
}

body .header-content-tooggle {
  width: 100%;
  max-width: 250px;
  box-shadow: 0px 6px 3px 3px #eee;
  position: absolute;
  z-index: 999;
  background-color: #450f25;
  height: 100%;
  display: none;
}

.navbar-toggler {
  box-shadow: none !important;
}

.New_section {
  display: flex;
  align-items: center;
}

body .logo-main-res {
  background-size: cover;
  max-width: 100%;
  position: relative;
  top: 00px;
  left: 0px;
  max-width: 120%;
  max-height: 155%;
}

body .logo-res {
  background-size: cover;
  max-width: 70%;
  position: relative;
  top: 00px;
  left: 0px;
  width: 40%;
  height: 40%;
}



.daterangepicker.ltr.show-calendar.opensright {
  background: #fff;
  box-shadow: 0px 6px 14px rgba(24, 39, 75, 0.12), 0px 10px 32px rgba(24, 39, 75, 0.1);
  border-radius: 8px;
}



.daterangepicker.ltr.show-calendar.opensright button {
  background: #FFFFFF;
  border: 1px solid #AE8340;
  border-radius: 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 32px;
  padding: 2px 18px;
  color: #AE8340;
}

.daterangepicker.ltr.show-calendar.opensright button:hover {
  background: #AE8340;
  ;
  color: #FFFFFF;
}

body .daterangepicker td.active,
body .daterangepicker td.active:hover {
  background-color: #AE8340 !important;
  border-color: transparent;
  color: #fff;
  border-radius: 43px;
  height: 32px
}

.daterangepicker .drp-buttons {
  padding: 16px;
}

.daterangepicker th.month {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #777391;
}

.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #2E294E;

}

.Calender_div {
  background-color: #FFFFFF;
  border: 1px solid #B1AFC0;
  border-radius: 8px;
  padding: 0px 16px;
  display: flex;
  width: 100%;
  min-width: 253px;
}

.Form_box input {
  background-color: transparent;
  border: none;
  padding: 0px 0px;
  color: #000;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.Form_box select:focus,
.Form_box input:focus {

  box-shadow: none;
}


/* media query */
@media (max-width: 1041px) {

  body .logo-main-res {
    background-size: cover;
    max-width: 100%;
    position: relative;
    top: 00px;
    left: 0px;
    max-width: 10%;
    max-height: 10%;
  }

  body .logo-res {
    background-size: cover;
    max-width: 70%;
    position: relative;
    top: 00px;
    left: 0px;
    width: 25%;
    height: 25%;
  }

}


/* media query */
@media (max-width: 1390px) {

  body .logo-main-res {
    background-size: cover;
    max-width: 100%;
    position: relative;
    top: 00px;
    left: 0px;
    max-width: 50%;
    max-height: 50%;
  }

  body .logo-res {
    background-size: cover;
    max-width: 70%;
    position: relative;
    top: 00px;
    left: 0px;
    width: 25%;
    height: 25%;
  }


  body .thali_section table span.thali_table span.thali_table {
    white-space: nowrap;
  }

}

/* media query */
@media (max-width: 1366px) {

  h1.title_heading span.Date_list {
    font-size: 14px;
    margin-left: 0px;
    padding: 0 0px;
  }

  body .thali_section table span.thali_table span.thali_table {
    white-space: nowrap;
  }

  /* body .Landing_div{
max-width: 250px;
} */

  body ul.thali_menu_list li {
    font-size: 12px;
    margin: 6px 6px;

  }

  body .sider_heading li span {
    font-size: 14px;
  }

  /* my css */
  body .sider_heading li a {
    margin: 4px 18px !important;
    padding: 16px 0;
    height: 46px !important;
  }

  /* my css */
  body ul.thali_menu_list p {
    font-size: 12px;
    line-height: 22px;
  }

  body button.Count_btn {
    font-size: 12px;
    padding: 6px 9px;
    margin: 6px 6px;
  }

  body .image-fit {
    width: 32px;
    object-fit: cover;
  }
}


@media (max-width: 1240px) {

  body .sider_heading p {
    font-size: 14px;
    margin: 5px 12px;

  }

  body .sider_heading li span {
    font-size: 14px;
  }

  body .sider_heading li a {
    padding: 20px 0;
    height: 24px;

  }

  body .sider_heading,
  body .main_section {
    margin-top: 19px;
  }

  body ul.thali_menu_list li {
    white-space: nowrap;
  }

  .thali_section table span.thali_table {
    white-space: nowrap;
    font-size: 12px;
  }

  body .Week_list {
    margin-bottom: 14px;
  }

  body .Week_Mini_list {
    margin-bottom: 5px;
  }

  .manu-card .Calendar_card p {
    padding: 0 5px;
  }
}

/* for mobile view screen */
@media (max-width: 767px) {
  body .logo-main-res {
    background-size: cover;
    max-width: 50%;
    position: relative;
    top: 00px;
    left: 0px;
    max-width: 20%;
    max-height: 10%;
  }


  body .logo-res {
    background-size: cover;
    max-width: 30%;
    position: relative;
    top: 00px;
    left: 0px;
    width: 50%;
    height: 20%;
  }

  body .login_text {
    background-size: cover;
    position: relative;
    display: none
  }

  body .log_in_page {
    position: unset;
    transform: none;
  }

  body .login_bg {
    max-height: 10;
    display: flex;
    align-items: center;
    padding: 0% 0px;
  }

  body .thali-flex {
    flex-direction: column;
  }

  body .Feedback_list {
    flex-direction: column;
  }

  body .Feedback_list .Form_box select {
    width: 100%;
    max-width: 333px;
    margin: auto;
  }

  .Feedback_list span {
    display: none;
  }

  body .Feedback_list .Form_box {
    width: 100%;
    padding: 5px 0px;
    max-width: 232px;
  }

  body .login_box {
    height: 70%;
    min-height: unset;
  }

  body form.Login_form {
    position: unset;
    transform: none;
    max-width: 100%;
  }

  body ul.login_text li {
    max-width: 363px;
    font-size: 14px;
  }

  body .logo-res {
    height: 70px;
  }

  body .design-img {
    height: 20px;
  }

  body section.main_section {
    width: 100%;
    overflow-x: scroll;
  }

  body .thali_table_section {
    overflow-x: scroll;
  }

  .css-uerakn {
    display: none;
  }

  body .header-content-tooggle {
    display: block;
  }

  .navbar-toggler {
    padding: 0px !important;
  }

  body .container-fluid {
    padding: 0px 14px;
  }

  body h1.title_heading {
    font-size: 16px;
  }

  body button.btn.thali_btn {
    font-size: 13px;
    padding: 8px 18px;
  }

  body.thali_section table span.thali_table {
    font-size: 12px;
  }

  body .Calendar_card h6 {
    font-size: 14px;
  }

  body .Calendar_card h1 {
    font-size: 34px;
  }

  body ul.thali_menu_list li {
    margin: 6px 10px;
  }

  body .thali_section table span.thali_table {
    padding: 13px 17px;
  }

  body button.Count_btn {
    padding: 6px 19px;
    margin: 6px 15px;
  }

  .sider_heading a.active li a svg path {
    fill: #ffffff !important;
  }

  .sider_heading a.active li a {
    border: 1px solid #ffffff !important;
  }

  body .sider_heading li span {
    color: white !important;
  }

  body .Week_list {
    padding: 20px 25px;
    margin: 5px 0px;
  }

  body .Week_Mini_list {
    padding: 10px 15px;
    margin: 5px 0px;
  }

  body .Week_list h1 {
    font-size: 21px;
  }

  body .Week_Mini_list h1 {
    font-size: 11px;
  }

  body .navbar-svg {
    fill: #ffffff !important;
  }

  body .navbar-menu.collapse {
    display: block;
    display: flex;
    justify-content: right;
  }

  body .navbar-brand {
    display: none;
  }

  body .nav-hide-icon {
    display: none;
  }

  body header.top_header .new-nav {
    display: flex;
    flex-flow: revert;
    justify-content: space-between;
  }

  body .down_btn button {
    min-width: 140px !important;
    padding: 7px 7px;
  }

  body .navbar-nav .dropdown-menu {
    position: absolute;
  }

}


/* for mini tablet screen */
@media(min-width: 768px) and (max-width: 991px) {
  body .logo-main-res {
    background-size: cover;
    max-width: 50%;
    position: relative;
    top: 00px;
    left: 0px;
    max-width: 20%;
    max-height: 20%;
  }

  body ul.thali_menu_list li {
    margin: 6px 0px;
  }

  body .thali_table_section::-webkit-scrollbar-thumb {
    width: 5px !important;
  }

  .Thali_page_btn.d-flex {
    float: right;
    justify-content: end;
    width: 100%;
    margin-top: 1cqi;
  }

  ul.pagination {
    margin-top: 16px;
  }

  .thili_div {
    flex-flow: wrap;
  }

  .header-content-tooggle .sider_heading li span {
    color: #fff;

  }

  .sider_heading p {
    color: #fff;
  }

  .header-content-tooggle .sider_heading li span svg path {
    fill: #fff;

  }

  body .log_in_page {
    position: unset;
    transform: none;
  }

  body .login_bg {
    max-height: 450px;
    display: flex;
    align-items: center;
    padding: 2% 0px;
  }

  body form.Login_form.ITS-Link .form-group {
    margin-top: 0%;
  }


  body .login_box {
    height: 100%;
    min-height: unset;
  }

  body .navbar-svg {
    fill: #ffffff !important;
  }

  body form.Login_form {
    position: unset;
    transform: none;
    max-width: 100%;
  }

  body ul.login_text li {
    max-width: 363px;
    font-size: 14px;
  }

  body .design-img {
    height: 20px;
  }

  body .login_info {
    margin-top: 20px;
  }

  body section.main_section {
    width: 100%;
    overflow-x: scroll;
  }

  body .thali_table_section {
    overflow-x: scroll;
  }

  .css-uerakn {
    display: none;
  }

  body .header-content-tooggle {
    display: block;
    height: min-content !important;

  }

  /* body ul.thali_menu_list li {
    margin: 6px 19px;
  } */

  body .thali_section table span.thali_table {
    padding: 13px 17px;
  }

  body button.Count_btn {
    padding: 6px 19px;
    margin: 6px 15px;
  }

  body .thali_table_section {
    overflow-x: scroll;
  }

  body ul.thali_menu_list p {
    margin: 5px 7px;
  }

  body .navbar-menu.collapse {
    display: block;
    display: flex;
    justify-content: right;
  }

  body .navbar-brand {
    display: none;
  }

  body .nav-hide-icon {
    display: none;
  }

  body header.top_header .new-nav {
    display: flex;
    flex-flow: revert;
    justify-content: space-between;
  }

  body .down_btn button {
    min-width: 140px !important;
    padding: 7px 7px;
  }

  body .navbar-nav .dropdown-menu {
    position: absolute;
  }

  body .new-nav .navbar-toggler {
    padding: 0px;
  }

  body .Thali_page .thali_count_list .form-group.has-search {
    padding: 0px 9px;
  }

  body .thali-flex {
    flex-direction: column;
    display: block;
  }

  body .Feedback_list {
    max-width: 707px;
    margin: 8px 0px;
  }

  body .No_Inventory h1 {
    font-size: 20px;
    line-height: 20px;

  }

  ul.pagination {
    flex-flow: wrap;
  }

  .calender-icon-div img {
    max-width: 40px;
    height: 40px;
  }
}

/* for tablet screen */
@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .Thali_page_btn.d-flex {
    float: right;
    justify-content: end;
    width: 100%;
    margin-top: 26px;
  }

  .thili_div {
    flex-flow: wrap;
  }

  .AddStandardInventory {
    flex-flow: wrap;
    justify-content: center;
  }

  body h1.title_heading {
    font-size: 20px;
  }

  body .Week_list {
    margin-bottom: 15px;
  }

  body .Week_Mini_list {
    margin-bottom: 5px;
  }

  body .Calender_div {
    padding: 0px 9px;
    min-width: 152px;
  }

  .New_section .Calender_div input.form-control {
    min-width: 109px;
    font-size: 12px;
    line-height: 11px;
  }

  body .Week_list h1 {
    font-size: 16px;
    line-height: 24px;
  }

  body .Week_Mini_list h1 {
    font-size: 12px;
    line-height: 14px;
  }

  body .Week_list {
    padding: 15px 15px;
  }

  body .Week_Mini_list {
    padding: 15px 15px;
  }

  ul.pagination {
    margin-top: 15px;
  }

  body section.main_section {
    width: 100%;
    overflow-x: scroll;
  }

  body ul.thali_menu_list li {
    margin: 6px 19px;
  }

  body .thali_section table span.thali_table {
    padding: 13px 17px;
  }

  body button.Count_btn {
    padding: 6px 19px;
    margin: 6px 15px;
  }

  body .thali_table_section {
    overflow-x: scroll;
  }

  body ul.thali_menu_list p {
    margin: 5px 7px;
  }

  body .thali-flex {
    flex-direction: column;
    display: block;
  }

  body .Feedback_list {
    max-width: 707px;
    margin: 8px 0px;
  }
}

/* media query */
@media (max-width: 400px) {

  body .logo-main-res {
    background-size: cover;
    max-width: 100%;
    position: relative;
    top: 00px;
    left: 0px;
    max-width: 70%;
    max-height: 70%;
  }

  body .logo-res {
    background-size: cover;
    max-width: 70%;
    position: relative;
    top: 00px;
    left: 0px;
    width: 50%;
    height: 50%;
  }

  body .login_text {
    background-size: cover;
    position: relative;
    display: none
  }

}


body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
  border-radius: 10px;
}

body::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb {
  background-color: #957137;
  border: 2px solid #957137;
}

.css-hlgwow {
  padding: 7px 10px !important;
}

h5.version {
  position: fixed;
  bottom: 0;
  right: 0;
}

@media (max-width: 390px) {
  body .login_bg {
    padding: 4% 0px;
  }


  body .logo-main-res {
    max-width: 50%;
    max-height: 50%;
  }

  body img.logo-res {
    width: 100%;
    max-width: 199px;
  }

  body form.Login_form.ITS-Link {
    padding-top: 40px;
  }

  body form.Login_form.ITS-Link .form-group {
    margin-top: 15%;
  }

  body form.Login_form.ITS-Link a.Link-its {
    font-size: 12px;

  }


  body form.Login_form h1 {
    padding-top: 1px;
    padding-bottom: 0px;
  }
}