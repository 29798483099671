h3 {
  font-size: 17px;
  font-weight: 600;
}

a {
  text-decoration: inherit !important;
  color: inherit !important
}



section.Login_page .row {
  height: 100vh;
}


.login_bg {
  /* background-color: #5f0e33; */
  height: 100%;
  position: relative;
background-image: url(../../img/bg.png);
background-size: cover;

}

ul.login_text li {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  display: flex;
  align-items: flex-start;
  width: 100%;
  max-width: 432px;
  margin: 10px auto;
  text-align: left;
}


ul.login_text {
  list-style: none;
  padding-left: 0;
  padding: 30px 0;
  margin-bottom: 0;
}

.login_info {
  text-align: center;
  /* /* padding-top: 100px; */
}

ul.login_text li img {
  margin-right: 20px;
}

.login_box {
  /* margin-bottom: 115px; */
  text-align: center;
  /* height: 100%;
  min-height: 325px; */
  height: 100%;
  min-height: calc(100vh - 400px);
}

.log_in_page {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

form.Login_form h1 {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: #2E294E;
  padding-top: 24px;
  padding-bottom: 25px;
}






section.Login_page .menu {
  position: relative;
  padding: 15% 13% 0 13%;
  display: flex;
  justify-content: center;
}

.menu h2 {
  display: inline;
  margin: 20px;
  padding-bottom: 3px;
  /* border-bottom: 3px solid #1161ee; */
}


.menu h2 {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}


a:hover {
  text-decoration: inherit;
}

/* a:not(.active) {
    cursor:inherit;
  } */
body .menu a.active h2 {
  border-bottom: 0 solid #1161ee !important;
  color: #AEAEAE !important;
  transition: color 0.5s ease-in !important;

}



form.Login_form .form-group label {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #2E294E;
  margin-bottom: 10px
}



form.Login_form .form-group input {
  display: block;
  margin: auto;
  padding: 10px;
  border-radius: 20px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  background: #FFFFFF;

  border: 1px solid #B1AFC0;
  border-radius: 8px;

}


button.btn.btn-primary.mr-1.submit_btn:hover  ,form.Login_form.ITS-Link a.Link-its:hover {
  background: linear-gradient(180deg, #AE8340 0%, #957137 100%);
  color: #fff !important;
}

button.btn.btn-primary.mr-1.submit_btn {
  width: 100%;
  padding: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #AE8340;
  opacity: 1;
  border-radius: 20px;
  cursor: pointer;
  background: #ffff;
  border: 1px solid #957137;
  box-shadow: 0px 2px 4px rgba(46, 41, 78, 0.19);
  border-radius: 12px;
}

form.Login_form {
  background: #fff;
  border: 1px solid #E7E6EE;
  box-shadow: 0px 4px 2px rgba(27, 46, 94, 0.02);
  border-radius: 8px;
  width: 100%;
  max-width: 477px;
  padding: 31px 39px;
  position: absolute;
  top: 50%;
  left: 75%;
  transform: translate(-50%, -50%);
}





.forget h4 {
  margin-bottom: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 32px;
  color: #AE8340;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.forget h4:hover {
  opacity: 0.8;
  transition: all 0.1s ease-in;
}


a.forget {
  float: right;
  margin: 16px 10px;
  margin-top: -16px;
}


form.Login_form .form-group {
  margin-bottom: 20px;
}

form.Login_form label span {
  color: red;
  font-weight: 900;
}

form.Login_form .form-group .form-control:focus {
  box-shadow: 0 0 0 0.1rem rgb(205 183 158);
}


section.Login_page {
  background: #F5F5F5;
  overflow: hidden;
}


input.form-control.is-invalid {
  border-color: #dc3545 !important;
}

p.error {
  color: #dc3545 !important;
}

.thili_div.container-fluid {
  margin-bottom: 18px;
}

body section.Login_page .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

body iframe.full-screen-iframe {
  width: 100%;
  height: 100vh;
}



/* its  */

form.Login_form.ITS-Link h1 {
  line-height: 26px;
  padding-bottom: 0;
  padding-top: 0;
  text-align: center;
  display: block;
  color: #440e26;
}


form.Login_form.ITS-Link {
  height: 100%;
  min-height: 459px;
  max-height: 459px;
  padding-top: 90px;
}

form.Login_form.ITS-Link .form-group {
  /* display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100%; */

  margin-top: 30%;
}


form.Login_form.ITS-Link  .form-group input  ,.Checkbox-hide .Form_box input {
  display: inline-block !important;
  margin-right: 10px !important;
}

form.Login_form.ITS-Link .form-group label ,.Checkbox-hide .Form_box label {
  padding-top: 0px !important;
  color: #AE8340 !important;
  margin-bottom: 0 !important;
}


form.Login_form.ITS-Link .Form_box.THALI-REGISTERED-check  ,.Checkbox-hide .Form_box.THALI-REGISTERED-check {
  text-align: end;
  margin-top: 20px;
}

form.Login_form.ITS-Link .THALI-REGISTERED-check .form-check-input ,.Checkbox-hide .THALI-REGISTERED-check .form-check-input {
  width: 1.5em;
  height: 1.5em;
}




form.Login_form.ITS-Link a.Link-its {
  background: #fff;
  border: 1px solid #957137;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(46,41,78,.19);
  color: #ae8340 !important;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  opacity: 1;
  padding: 10px !important;
  width: 100% !important;
  display: block;
  text-align: center;
  text-decoration: none;
  outline: none;
}

form.Login_form.ITS-Link .form-check-input:checked  ,.Checkbox-hide  .form-check-input:checked {
  background-color: #957137;
  border-color: #957137;

}
.Checkbox-hide .THALI-REGISTERED-check .form-check-input {

  border: 1px solid #957137;
  border-color: #957137;
}