/* thalicss */

h1.title_heading {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: #2E294E;
  margin-bottom: 0;
}

button.btn.thali_btn:hover {
  background: linear-gradient(180deg, #AE8340 0%, #957137 100%);
  color: #FFF;


}

button.btn.thali_btn {
  background: #fff;
  border: 1px solid #957137 !important;
  box-shadow: 0px 2px 4px rgb(46 41 78 / 19%);
  border-radius: 5px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  padding: 12px 28px;
  color: #957137;

}


section.main_section {
  width: 100%;
}

.thili_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}



.thali_section table span.thali_table {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #777391;
  padding: 13px 6px;
  /* white-space: nowrap; */
}

.Calendar_card h6 {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #591333;
  background: #FCEEF4;
  padding: 8px 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

}

.user_Calendar_card h6 {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  text-align: center;
  color: #591333;
  background: #FCEEF4;
  padding: 6px 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

}

.user_Inv_Calendar_card h6 {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 8px;
  text-align: center;
  color: #591333;
  background: #FCEEF4;
  padding: 4% 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

}

.Calendar_card h1 {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 38px;
  line-height: 32px;
  color: #2E294E;
  text-align: center;
  margin-bottom: 6px;
}

.user_Calendar_card h1 {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 12px;
  color: #2E294E;
  text-align: center;
  margin-bottom: 6px;
}

.user_Inv_Calendar_card h1 {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 8px;
  color: #2E294E;
  text-align: center;
}

.user_Inv_Calendar_card h5 {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 8px;
  color: #2E294E;
  text-align: center;
}

.Calendar_card p {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #777391;
  margin-bottom: 0;
}


.user_Calendar_card p {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #777391;
  margin-bottom: 0;
}

.user_Inv_Calendar_card p {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 100;
  font-size: 2px;
  text-align: center;
  color: #777391;
  margin-bottom: -10;
}

.Calendar_card {
  background: #fff;
  border: 1px solid #E7E6EE;
  box-shadow: 0px 4px 2px rgb(27 46 94 / 2%);
  border-radius: 8px;
  padding-bottom: 10px;
  width: 100%;
  min-width: 98px;
  margin: 20px auto;
  margin-top: 0;
  max-width: 140px;
  margin-bottom: 0;
}

.user_Calendar_card {
  background: #fff;
  border: 1px solid #E7E6EE;
  box-shadow: 0px 4px 2px rgb(27 46 94 / 2%);
  border-radius: 8px;
  width: 100%;
  min-width: 98px;
  padding-bottom: 5px;
  margin: 20px auto;
  margin-top: 0;
  max-width: 140px;
  margin-bottom: 0;
}

.user_Inv_Calendar_card {
  background: #fff;
  border: 1px solid #E7E6EE;
  box-shadow: 0px 4px 2px rgb(27 46 94 / 2%);
  border-radius: 8px;
  width: 100%;
  min-width: 10px;
  margin: 5px auto;
  margin-top: 0;
  max-width: 80px;
  margin-bottom: 0;
}


ul.thali_menu_list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

ul.thali_menu_list li {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #2E294E;
  padding: 0.2rem 0.2rem;
  display: flex;
  align-items: center;
  text-transform: none;
  cursor: pointer;
}

ul.thali_mobile_menu_list li {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: bold;
  font-weight: 500;
  font-size: 15px;
  color: #2E294E;
  padding: 0.4rem 1.5rem;
  text-transform: none;
  max-width: 700px;
  cursor: pointer;
}

ul.thali_menu_caterer_list li {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #2E294E;
  padding: 0.2rem 0.2rem;
  display: flex;
  align-items: center;
  text-transform: none;
  cursor: pointer;
  width: 200px;
  max-width: 95%;
  margin-left: -30px;
}

ul.thali_menu_list li img {
  margin-right: 1px;
}


ul.thalireg_menu_list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

ul.thalireg_menu_list li {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14.5px;
  line-height: 15px;
  color: #2E294E;
  /* color: #490B26; */
  padding: 0.2rem 0.2rem;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  text-transform: none;
  cursor: pointer;
}

ul.thalireg_menu_list li img {
  margin-right: 1px;
}

ul.inv_user_menu_list li {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11.5px;
  line-height: 10px;
  color: #2E294E;
  /* color: #490B26; */
  padding: 0.2rem;
  align-items: center;
  text-transform: none;
}


ul.thalireg_user_menu_list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

ul.inv_user_menu_list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

ul.thalireg_user_menu_list li {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11.5px;
  line-height: 12px;
  color: #2E294E;
  /* color: #490B26; */
  padding: 0.2rem 0.2rem;
  display: flex;
  width: 250;
  align-items: center;
  text-transform: none;
  cursor: pointer;
}

ul.thalireg_user_menu_list li img {
  margin-right: 1px;
}


/* 
  .thali_section table.table.table-bordered {
    margin-top: 0px;
  } */



button.Count_btn {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #957137;
  background: #F9F6F0;
  border: 1px solid #957137;
  border-radius: 8px;
  padding: 6px 12px;
  margin: 14px 13px;
}

button.Inv_Count_btn {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #957137;
  background: #F9F6F0;
  border: 1px solid #957137;
  border-radius: 8px;
  padding: 6px 12px;
  margin-left: 10px;
}

button.Inv_Cal_Count_btn {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #957137;
  background: #F9F6F0;
  border: 1px #957137;
  border-radius: 8px;
  padding: 6px 12px;
  margin-left: 10px;
  margin-top: -16px;
}



ul.thali_menu_list p {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #2E294E;
  margin: 14px 0px;
  width: 100%;
  max-width: 128px;
  word-break: break-all;
  margin-bottom: 0;
}




ul.thali_menu_list.edit_icon li {
  margin: 14px 32px;
  text-align: center;
  /* display: flex; */
}

/* .thali_table_section {
  display: block;
  height: calc(100vh - 244px);

  overflow-y: inherit;
  overflow-x: hidden;
} */


.thali_table_section::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.thali_table_section::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

.thali_table_section::-webkit-scrollbar-thumb {
  background-color: #957137;
  border: 2px solid #957137;
}



/* Export Inventory */

.Back-page {
  display: flex;
  align-items: center;
}


.Week_list .week_img {
  background: #EEE4D2;
  border-radius: 16px;
  width: 56px;
  height: 56px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 19px;

}

.Week_Mini_list .week_img {
  background: #EEE4D2;
  border-radius: 16px;
  width: 36px;
  height: 36px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 19px;

}




.Week_list h4 {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #2E294E;
}

.Week_Mini_list h4 {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 8px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #2E294E;
}

.Week_list h1 {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #957137;
  margin-bottom: 0;
}

.Week_Mini_list h1 {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 12px;
  color: #957137;
  margin-bottom: 0;
}

.Week_list {
  display: flex;
  align-items: center;
  background: #F9F6F0;
  border: 1px solid #EEE4D2;
  border-radius: 16px;
  padding: 25px 25px;
  cursor: pointer;
}

.Week_Mini_list {
  display: flex;
  align-items: center;
  background: #F9F6F0;
  border: 1px solid #EEE4D2;
  border-radius: 16px;
  padding: 10px 10px;
  cursor: pointer;
}

.Week_list.active {
  /* background: red; */
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.Week_Mini_list.active {
  /* background: red; */
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.ExportInventory_calender {
  padding-top: 20px;
}

.Back-page a {
  background: #FFFFFF;
  border: 1px solid #E7E6EE;
  border-radius: 42px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.Week_list.Thali_Count {
  background: #FCEEF4;
  border: 1px solid #F8DDE9;

}

.Week_list.Thali_Count .week_img {
  background: #F8DDE9 !important;
}

.Week_list.Thali_Count h1 {
  color: #430E26 !important;
}

.Week_list.INGREDIENTS_Cout {
  background: #E6EEEF;
  border: 1px solid #CDDCE0;
}

.Week_list.INGREDIENTS_Cout .week_img {
  background: #CDDCE0 !important;
}

.Week_list.INGREDIENTS_Cout h1 {
  color: #1F2F32 !important;
}

.ExportInventory_calender .thali_table_section {
  margin-top: 20px;
}

h1.title_Allow {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #2E294E;
  margin-bottom: 0;
}

.ExportInventory_calender .thali_table_section {
  display: block;
  /* height: calc(100vh - 379px); */
  /* max-height: calc(100vh - 379px); */

}

.ExportInventory_calender .form-check-input:checked {
  /* border-color: #0d6efd; */
  background: #AE8340;
  /* border-radius: 8px; */
}

.ExportInventory_calender .form-check {
  margin-bottom: 0;
}

.Week_list:hover {
  /* box-shadow: 0 2px 4px rgb(0 0 0 / 15%); */
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
}

/* ThaliPage */

h1.title_heading span.Date_list {
  font-size: 16px;
  font-weight: 600;
  margin-left: 20px;
  background: #F9F9F9;
  border-radius: 12px;
  padding: 0px 12px;
}


.Back-page a:hover {
  background: #f0e4d3;
}

.Thali_page .thali_count_list .has-search .form-control {
  padding-left: 2.375rem;
  height: 48px;
  border: none;
  background-color: transparent;
}

.Thali_page .thali_count_list .has-search .form-control:focus {
  box-shadow: none;
}

.Thali_page .thali_count_list .has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 3.10rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

/* 
.Thali_page .thali_count_list .form-group.has-search {
  padding: 0 20px;
} */

.Thali_page .thali_count_list {
  background: #F9F9F9;
  border-width: 1px 0px;
  border-style: solid;
  border-color: #E7E6EE;
  margin-top: 20px;
  margin-bottom: 20px;
}


.dropdown.action_btn button#dropdownMenuButton2 {
  background: #F9F9F9;
  /* Black/Divider */
  border: 1px solid #E7E6EE;
  border-radius: 33px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 3px 9px;
  color: gray;
}

.dropdown.action_btn button#dropdownMenuButton2::after {
  border: none;
}


.dropdown.action_btn a.dropdown-item {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #2E294E !important;
}

.dropdown.action_btn .dropdown-item:focus,
.dropdown.action_btn a.dropdown-item:hover {
  background: transparent;
  color: #957137 !important;
}

.dropdown.action_btn a.dropdown-item:hover>svg path {
  fill: #957137 !important;
}

.dropdown.action_btn .dropdown-toggle.show {
  background-color: #957137 !important;


}

.dropdown.action_btn .dropdown-toggle.show i {
  color: #ffff;
}

/* modal css */

.Popup_heding h1 {
  font-style: normal;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #2E294E;
  margin-bottom: 0;
}

.Popup_heding p {
  font-style: normal;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #777391;
  margin-bottom: 0;
}

div#edit_popup {
  width: 100%;
  max-width: 480px;
  height: auto;
  /* max-height: 460px; */
  background: #FFFFFF !important;
  border: 1px solid #E7E6EE !important;
  box-shadow: 0px 4px 2px -2px rgba(27, 46, 94, 0.02);
  border-radius: 16px !important;
}



div#edit_user_register {
  width: 200%;
  max-width: 1540px;
  height: auto;
  /* max-height: 460px; */
  background: #FFFFFF !important;
  border: 1px solid #E7E6EE !important;
  box-shadow: 0px 4px 2px -2px rgba(27, 46, 94, 0.02);
  border-radius: 16px !important;
  padding: 20px 0 !important;
  /* max-height: 600px; */
  font-family: 'Plus Jakarta Sans', sans-serif;
}

div#edit_user_register .popup_modal {
  padding: 0 20px !important;
  margin-bottom: 30px;
}

div#edit_user_register .popup_modal {
  padding: 0 20px !important;
  margin-bottom: 30px;
}

div#edit_user_register .popup_modal {
  margin-bottom: 10px;
}

div#edit_user_register .Thali_page_btn_from {
  float: right;
  margin: 10px 20px;
}



.Popup_heding {
  display: flex;
  /* align-items: baseline; */
}



.popup_modal button {
  border: none;
  background: transparent;
}


.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: #000000bd !important;
}



ul.edit_heding li h1 {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #2E294E;
}

ul.edit_heding {
  list-style: none;
  padding-left: 0;
  align-items: center;
  display: flex;
  margin-top: 30px;
  border-top: 1px solid #E7E6EE;
  padding: 24px;
  border-bottom: 1px solid #E7E6EE;
  background: #F9F9F9;
}



ul.edit_heding li span {
  color: #E7E6EE;
  margin: 0 14px;
}


.edit_from form label {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #2E294E;
  margin-bottom: 10px;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.edit_from form label span {
  color: red;
}

.edit_from form select.form-select,
.edit_from form input {
  background-color: #FFFFFF;
  border: 1px solid #B1AFC0;
  border-radius: 8px;
  padding: 12px 16px;
  color: #777391;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.edit_from form select.form-select:focus {
  box-shadow: 0 0 0 0.1rem rgb(205 183 158);
}



.edit_from form .form-group {
  margin-bottom: 16px;
}

.Thali_page_btn_from button.btn.thali_btn {
  font-size: 14px;
  border-color: transparent;
  padding: 12px 30px;
  box-shadow: none;
}

.Thali_page_btn_from {
  float: right;
  margin: 10px 0;
}



.Remove_from h1 {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #2E294E;
  margin: 20px 0;
}

.Remove_from p {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #777391;
}

.Remove_from .Thali_page_btn_from {
  margin: 13px 0;
  display: flex;
  flex-flow: column-reverse;
  float: unset;
}

.Remove_from {
  width: 100%;
  max-width: 380px;
  text-align: center;
  margin: 20px auto;
}

ul.thali_menu_list li .expertimg {
  width: 100%;
  max-width: 76px;
  height: 42px;
  border-radius: 12px;
  object-fit: cover;

}

/* TotalIngredients */


.ExportInventory.TotalIngredients ul.thali_menu_list li {
  margin: 6px 0px;
}


.ExportInventory.TotalIngredients .thali_table_section.TotalIngredientstable {
  overflow-y: hidden;
  overflow-x: hidden;
  height: auto;
}

.thali_table_section.TotalIngredientstable ul.thali_menu_list p {
  max-width: 223px;
}

/* dishespage */

body .Dishes_canvas {
  width: 100% !important;
  max-width: 820px !important;
  margin: 15px 0;
  background: #FFFFFF !important;
  box-shadow: 0px 4px 2px rgba(27, 46, 94, 0.02) !important;
  border-radius: 4px !important;
}


body .Dishes_canvas h5#offcanvasRightLabel {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: #2E294E;
}



body .Dishes_canvas .Img_upload input[type="file"] {
  object-fit: cover;
  width: 100%;
  position: absolute;
  top: 50%;
  height: 112px;
  max-width: 320px;
  transform: translate(-50%, -50%);
  left: 50%;
  opacity: 0;
  z-index: 999;
}


body .Dishes_canvas .Img_upload {
  position: relative;
  position: relative;
  width: 100%;
  max-width: 345px;
  height: 100%;
  min-height: 170px;
  max-height: 170px;

}


body .Dishes_canvas img.img-firud.uplode_img {
  height: 170px;
  max-width: 345px;
  position: relative;
  width: 100%;
  object-fit: contain;
}


body .Dishes_canvas .Dish_Name_list label {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #2E294E;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

body .Dishes_canvas .Dish_Name_list input,
.Dish_Name_list select {
  border: 1px solid #B1AFC0;
  border-radius: 8px;
  padding: 11px 10px;
}


body .Dishes_canvas .Dish_Name_list .form-control:focus,
body .Dishes_canvas .Dish_Name_list select:focus,
.Form_box .css-13cymwt-control:focus {
  box-shadow: 0 0 0 0.1rem rgb(205 183 158);
  border-color: transparent;
}

.Form_box .css-1fdsijx-ValueContainer {
  padding: 7px 10px;
}

.Form_box .css-13cymwt-control {
  /* box-shadow: 0 0 0 0.1rem rgb(205 183 158); */
  border: 1px solid #B1AFC0;
}

body .Dishes_canvas button.Remove_btn img {
  width: 44px;
  height: 44px;
  background: transparent;
}

body .Dishes_canvas button.Remove_btn {
  outline: none;
  border: none;
  background: transparent;
  /* margin-top: 29px; */
  margin-left: -10px;
}

body .Dishes_canvas .Dish_Name_list {
  margin-top: 20px;
}

body .Dishes_canvas .Form_box {
  margin-bottom: 10px;

}

body .Dishes_canvas .Ingredients_title h1 {
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: #2E294E;
}

body .Dishes_canvas .Ingredients_title button,
body .offcanvas.calender_canvas.offcanvas-end .Ingredients_title button {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: #AE8340;
  background: #FFFFFF;
  border: 1px solid #AE8340;
  border-radius: 8px;
  outline: none;
  padding: 0px 24px;
  margin: 0 6px
}

body .Dishes_canvas .Ingredients_title button:hover,
body .offcanvas.calender_canvas.offcanvas-end .Ingredients_title button:hover {
  background-color: #AE8340;
  color: #fff;
}

body .Dishes_canvas .Ingredients_title {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  background: #fff;
  padding: 15px;
  
}

body .Dishes_canvas .Save_btn,
body .offcanvas.calender_canvas.offcanvas-end .Ingredients_title .Save_btn {
  display: flex;
}

body .Dishes_canvas .Save_btn button {
  margin: 0 6px;
}

.sider_heading a.active li a {
  border: 1px solid #AE8340;
  border-radius: 8px;
}

.sider_heading a.active li a span {
  color: #957137;
  font-weight: 600;
}

.sider_heading a.active li a svg path {
  fill: #957137;
}


body .vendor_canvas .Img_upload {
  position: relative;
  width: 100%;
  max-width: 116px;
  height: 100%;
  max-height: 116px;
}

body .vendor_canvas img.img-firud.uplode_img {
  position: absolute;
  width: 100%;
  max-width: 116px;
  height: 100%;
  object-fit: contain;
  top: 0;
  cursor: pointer;
}



.checkbox_btn {
  display: flex;
  align-items: center;
}


body .checkbox_btn label.form-check-label,
body .group_check label.form-check-label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #777391;
}




body .Dishes_canvas .checkbox_btn input {
  border-radius: 17px;
  padding: 8px 8px;
  margin: 4px 11px;
}

body .Dishes_canvas .checkbox_btn .form-check-input:checked {
  background-color: #AE8340;
}

body .Dishes_canvas .checkbox_btn .form-check-input:focus {
  border-color: #AE8340;
  ;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #ae8340;
}


body .Dishes_canvas .checkbox_btn .form-check {
  background: #FFFFFF;
  border: 1px solid #E7E6EE;
  border-radius: 12px;
  width: 100%;
  padding: 11px 8px !important;
  max-width: 123px;
  margin-right: 10px;
}


body .group_check .form-check {
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #E7E6EE;
  border-radius: 12px;
  padding: 11px 20px !important;
  justify-content: space-between;
  margin: 12px 0px;
}

body .group_check .form-check p {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #B1AFC0;
  margin-bottom: 0;
}

body .group_check .form-check input {
  border: 1px solid #B1AFC0;
  border-radius: 4px;
  padding: 11px 10px;
  margin-left: 0;
  margin: 0 0px;
  margin-right: 12px;
}


body .group_check .form-check .form-check-input:checked {
  background-color: #AE8340;

}

body .group_check .form-check .form-check-input:focus {
  outline: 0;
  box-shadow: none
}




body div#edit_popup_user .edit_from {
  margin-top: 20px;
}

div#edit_popup_user {
  width: 100%;
  max-width: 480px;
  border-radius: 11px !important;
}


div#edit_popup_user button.btn.thali_btn {
  font-size: 14px;
  border-color: transparent;
  padding: 10px 42px;
  box-shadow: none;
}


div#edit_popup_user .form-group {
  margin: 34px 0;
}

.Feedback_list {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 627px;
  align-items: center;
}

.Feedback_list .Form_box select {
  width: 100%;
  max-width: 180px;
}



.Feedback_list .Form_box select {
  background-color: #FFFFFF;
  border: 1px solid #B1AFC0;
  border-radius: 8px;
  padding: 8px 16px;
  color: #777391;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Plus Jakarta Sans', sans-serif;
}


.New_section .Form_box {
  width: unset;
  margin-right: 11px;
}

.Form_box {
  width: 100%;
}

.new_thali_menu_list {
  width: 162%;
}

.new_notes_user {
  width: 138%;
}

.New_Form_box {
  margin-right: 300px;
  max-width: 350;
}

textarea#TestlistNew {
  font-size: 13px;
}

.Feedback_list span {
  color: #B1AFC0;
  margin: 0 20px;
}

body .ingredients-img {
  width: 100%;
  height: 80px;
  max-width: 80px;
  border-radius: 10px;
  object-fit: cover;

}

.daterangepicker td.in-range {
  background-color: transparent !important
}

.thali_menu_list.star_rating button.btn.btn-secondary.dropdown-toggle {
  background: transparent;
  border: none;
  color: #2E294E;
  padding: 0;
  line-height: 0;
}

.thali_menu_list.star_rating ul.dropdown-menu.show h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
  padding: 14px 15px;
  display: flex;
  align-items: center;
  color: #2E294E;
  border-bottom: 1px solid #E7E6EE;
  font-family: 'Plus Jakarta Sans', sans-serif;
  padding-bottom: 20px;
}

.thali_menu_list.star_rating ul.dropdown-menu.show h1 span {
  font-weight: 400;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.img-setup {
  width: 60px;
  height: 44px;
  object-fit: contain;
  border-radius: 13px;
}

.thali_menu_list.star_rating ul.dropdown-menu.show {
  width: 100%;
  min-width: 500px !important;
  padding: 10px 0px;
}

.thali_menu_list.star_rating a.dropdown-item {
  display: flex;
  align-items: center;
  padding: 0;
}


.thali_menu_list.star_rating .progress-bar {
  /* height: 8px !important; */
  color: #957137;
  background: #957137;
}


.thali_menu_list.star_rating ul.dropdown-menu a.dropdown-item h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #777391;
  margin-bottom: 0;
  margin-left: 14px;
  width: 100%;
  max-width: 25px;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.thali_menu_list.star_rating .progress {
  height: 8px;
  width: 100%;
  background: #f0e4d3;
}

.thali_menu_list.star_rating .dropdown button.btn.btn-secondary.dropdown-toggle img {
  margin-right: 2px;
}

.thali_menu_list.star_rating button.btn.btn-secondary.dropdown-toggle:after {
  content: unset;
}

/* user Regis */
body .Register_user_input .form-check-input:focus,
.THALI-REGISTERED-check .form-check-input:focus {
  border-color: #440e26;
  outline: 0;
  box-shadow: none !important
    /* background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e"); */
}


/* body .Register_user_input .form-check-input:focus{
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e');
} */

body .Register_user_input .form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
}

.Register_user_input .form-switch .form-check-input {
  width: 4em;
  height: 2em;
}

.THALI-REGISTERED-check .form-check-input {
  width: 2em;
  height: 2em;
}

.Register_user_input .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
}


.Register_user_input .form-check-input:checked,
.THALI-REGISTERED-check .form-check-input:checked {
  background-color: #440e26;
  border-color: #440e26;
  /* background-color: #AE8340;
  border-color: #AE8340; */
  /* background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e") !important; */
}


.Register_user_input2 .form-check-input:checked,
.THALI-REGISTERED2-check .form-check-input:checked {
  background-color: #AE8340;
  border-color: #AE8340;
  /* background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e") !important; */
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 9999999999999999;
}

.img-setup {
  width: 79px;
  height: 47px;
  border-radius: 8px;
}

body .vender_img .vender_input {
  width: 100% !important;
  position: absolute !important;
  top: 50% !important;
  height: 112px !important;
  max-width: 307px !important;
  left: 50% !important;
  z-index: 999 !important;
}

.Img_upload.vender_img img {
  width: 118px;
  height: 118px;
  object-fit: cover;
}

span.Line_div {
  color: #B1AFC0;
  padding-right: 12px;
}

/* Registers page */

div#edit_register {
  width: 100%;
  max-width: 740px;
  height: auto;
  /* max-height: 460px; */
  background: #FFFFFF !important;
  border: 1px solid #E7E6EE !important;
  box-shadow: 0px 4px 2px -2px rgba(27, 46, 94, 0.02);
  border-radius: 16px !important;
  padding: 20px 0 !important;
  /* max-height: 600px; */
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.Registers_tabal span.thali_table {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #777391;
  padding: 5px 10px;
}


.Registers_tabal .Calendar_card {
  border-radius: 8px;
  padding-bottom: 10px;
  width: 100%;
  min-width: 80px;
  margin: 14px 0px;
  max-width: 98px;
}

.Registers_tabal .Calendar_card h6 {
  font-size: 12px;
}

.Registers_tabal .Calendar_card h1 {
  font-size: 32px;
}


.Registers_tabal .Register_user_input .form-switch .form-check-input {
  width: 3em;
  height: 1.5em;
}

.Registers_tabal .THALI-REGISTERED-check .form-check-input {
  width: 2em;
  height: 2em;
}

.Registers_tabal .Register_user_input .form-check-input:checked,
.Registers_tabal .THALI-REGISTERED-check .form-check-input:checked,
.Procurementtabel input[type="checkbox"]:checked {
  background-color: #AE8340;
  border-color: #AE8340;
}

div#edit_register .popup_modal {
  padding: 0 20px !important;
  margin-bottom: 30px;
}

.Registers_tabal tr th {
  vertical-align: middle;
}

.Registers_tabal {
  height: calc(100vh - 352px);
  overflow-y: inherit;
  overflow-x: hidden;
}

.Registers_tabal::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.Registers_tabal::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

.Registers_tabal::-webkit-scrollbar-thumb {
  background-color: #957137;
  border: 2px solid #957137;
}

div#edit_register .Thali_page_btn_from {
  float: right;
  margin: 10px 20px;
}

.rating_star .css-13cymwt-control {
  border: 1px solid #B1AFC0;
  border-radius: 8px;
}

.rating_star .css-1fdsijx-ValueContainer {
  padding: 5px 10px;
}


/* calender event */


.event-green {
  background-color: #7bea7b;
  border-color: #3f933f;
  color: #000000;
}

.Menu_test {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #2E294E;
  padding-bottom: 13px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  margin-top: 6px;
}

button.rbc-button-link {
  padding: 15px 2px;
  margin-bottom: 15px;
}


button.rbc-button-link span {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #2E294E;
  /* padding: 14px 0; */
  font-family: 'Plus Jakarta Sans', sans-serif;
}


.event-title {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  color: #2E294E;
  padding-bottom: 0px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  /* word-break: break-all; */
  white-space: pre-wrap;
}


.event-title img {
  margin-right: 11px;
}


.custom-event {
  padding: 7px 10px;
  padding-top: 0;
}

.rbc-label.rbc-time-header-gutter {
  display: none;
}

.fateha_data.fateha_div {
  margin-top: 30px;
}


.fateha_data {
  margin-bottom: 30px;
}



button.btn.event_btn {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #AE8340;
  width: 100%;
  border: 1px solid #AE8340;
  text-align: center;
  display: block;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.rbc-today {
  background-color: transparent !important;
  color: #000 !important;
}

.rbc-event:focus,
.rbc-day-slot .rbc-background-event:focus {
  outline: 5px auto #AE8340 !important;
}

body .rbc-date-cell button.rbc-button-link {
  padding: 13px 0;
  margin-bottom: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: #777391;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

body .rbc-date-cell {
  text-align: center;
}


.rbc-header span {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  display: block;
  /* align-items: center; */
  text-align: center;
  color: #2E294E;
  padding: 15px 0;
  font-family: 'Plus Jakarta Sans', sans-serif;
}



.rbc-header.rbc-today span {
  color: #fff;
}

.rbc-month-view,
.rbc-header,
.rbc-day-bg+.rbc-day-bg {
  border: 1px solid #E7E6EE !important;
}

.Modal_heding h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #2E294E;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.Modal_heding h1 span {
  color: #2E294E;
  font-weight: 500;
  font-size: 12px;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

div#edit_calender_popup {
  width: 100%;
  max-width: 569px;
  height: auto;
  /* max-height: 460px; */
  background: #FFFFFF !important;
  border: 1px solid #E7E6EE !important;
  box-shadow: 0px 4px 2px -2px rgba(27, 46, 94, 0.02);
  border-radius: 16px !important;
}



.Modal_heding {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.calender_tabal span.thali_table {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #2E294E;
  font-family: 'Plus Jakarta Sans', sans-serif;
}


.calender_tabal ul.thali_menu_list li {
  font-size: 12px;
  line-height: 24px;
  color: #2E294E;
  margin: 10px 6px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  white-space: nowrap;
}

.calender_tabal ul.thali_menu_list.new_data li {
  white-space: unset;
  word-break: break-all;
}


.calender_tabal {
  margin-top: 20px;
}

.rbc-today button.rbc-button-link span {
  /* background-color: transparent !important; */
  color: #2E294E !important;
}

span.rbc-btn-group button {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* display: flex; */
  /* align-items: center; */
  color: #777391;
  border: none !important;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

span.rbc-btn-group,
.month_btn {
  padding: 5px 9px;
  background: #F9F9F9;
  border: 1px solid #E7E6EE;
  border-radius: 8px;
  display: flex;
  flex-flow: row-reverse;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.rbc-btn-group button.rbc-active,
.month_btn button.active {
  background: #fff !important;
  border-radius: 6px;
}


body .rbc-toolbar {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  margin: 10px 10px;
  margin-top: 0;
}

.rbc-toolbar-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rbc-toolbar-buttons button {
  margin: 0 5px;
}



.calender_titile h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: #2E294E;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.month_btn button {
  background: transparent !important;
  border: none !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px !important;
  color: #777391 !important;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.rbc-toolbar-buttons button,
.rbc-toolbar-buttons button:focus-visible {
  background: transparent !important;
  border: none !important;
  padding: 11px 13px;
}




.rbc-month-view .rbc-event .Menu_test {
  display: none;
}

.rbc-month-view .rbc-event .event-title img {
  display: none;
}

.rbc-month-view .rbc-event .event-title {
  padding-bottom: 0;
}



.rbc-time-view .week_list.d-none,
.rbc-month-view .rbc-event .d-none.show_btn {
  display: block !important;
}

.rbc-month-view .rbc-event ul.select_div.d-none {
  display: block !important;
}

.rbc-month-view .rbc-event ul.select_div.d-none {
  display: block !important;
  color: #2E294E;
  list-style: disc;
  margin-bottom: 0px;
}

.rbc-month-view .rbc-event .fateha_data {
  display: none;
}

.rbc-month-view .rbc-event .d-none.show_btn {
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 20px;
  color: #2E294E;
  margin-left: 6px;
  font-family: 'Plus Jakarta Sans', sans-serif;
}



/* .rbc-month-view .rbc-event ul.select_div.d-none li:nth-child(3),
.rbc-month-view .rbc-event ul.select_div.d-none li:nth-child(4) {
  display: none !important;
} */

/* 
.rbc-time-view .custom-event {
  display: none;
} */





/* .rbc-time-view  .rbc-time-content {
  display: none;
} */

.rbc-time-view .rbc-row.rbc-time-header-cell.rbc-time-header-cell-single-day .custom-event {
  display: block !important;
}


/* body .rbc-time-content {
  display: none;
} */

.offcanvas.calender_canvas {
  width: 100% !important;
  max-width: 720px !important;
  margin: 0px 0;
  /* background: red; */
  box-shadow: 0px 4px 2px rgba(27, 46, 94, 0.02) !important;
  border-radius: 4px !important;
  top: 50% !important;
  z-index: 99999 !important;
  position: fixed !important;
  bottom: unset !important;
  visibility: visible;
  right: 0% !important;
  z-index: 9999999 !important;
  height: auto;
  padding: 20px 25px;
  transform: translate(0%, -50%) !important;

}


.offcanvas.calender_canvas .offcanvas-header .title_heading {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  color: #2E294E;
  margin-right: 25px;
  margin-bottom: 0;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.offcanvas.calender_canvas .offcanvas-header .Date_list {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2E294E;
  border-radius: 8px;
  padding: 6px 20px;
  background: #F9F9F9;
  margin-left: 20px;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.title_div {
  display: flex;
  align-items: center;
}

.hide-time-slot {
  display: none;
}

.rbc-time-view .rbc-label {
  display: none;
}

.rbc-allday-cell {
  height: calc(100vh - 98px);
  max-height: unset;
}

.rbc-time-content {
  display: none;
}

.rbc-time-content {
  display: none;
}


h5.Calnder_tab_heding {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #2E294E;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.Calnder_tab li.nav-item label.form-check-label,
.Menu_class .Form_box label.form-label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #777391;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.Calnder_tab li.nav-item .form-check-input:checked {
  background-color: #AE8340;
  border-color: #AE8340;
}


.Calnder_tab li.nav-item .form-check-input:focus {
  box-shadow: unset;
}

.Calnder_tab ul {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  border-bottom: none;

}

.Calnder_tab li.nav-item {
  width: 100%;
  border: 1px solid #E7E6EE;
  border-radius: 12px;
  max-width: 322px;
  padding: 10px 10px;
}

.Calnder_tab li.nav-item.tab2,
.Calnder_tab li.nav-item.tab1,
.form-check.tab1 {
  border: 1px solid #AE8340 !important;
}



.offcanvas.calender_canvas .offcanvas-header {
  margin-bottom: 20px;
}


div#myTabContent {
  margin-top: 20px;
  margin-bottom: 20px;
}

h1.Caterer_heading {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: #2E294E;
  margin-top: 25px;
  font-family: 'Plus Jakarta Sans', sans-serif;
}


body .offcanvas.calender_canvas select body .offcanvas.calender_canvas select {
  border: 1px solid #B1AFC0;
  border-radius: 10px;
  padding: 10px 10px;
}


body .offcanvas.calender_canvas .form-control:focus,
body .offcanvas.calender_canvas select:focus,
.offcanvas.calender_canvas .Form_box .css-13cymwt-control:focus {
  box-shadow: 0 0 0 0.1rem rgb(205 183 158);
  border-color: transparent;
}

.offcanvas.calender_canvas .Form_box .css-1fdsijx-ValueContainer {
  padding: 7px 10px;
}

.offcanvas.calender_canvas .Form_box .css-13cymwt-control {
  /* box-shadow: 0 0 0 0.1rem rgb(205 183 158); */
  border: 1px solid #B1AFC0;
  border-radius: 10px;
}


.offcanvas.calender_canvas .Roti_list h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: #2E294E;
  margin-bottom: 0;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.offcanvas.calender_canvas .Roti_list button {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #AE8340;
  border: 1px solid #AE8340;
  padding: 5px 29px;
}

.offcanvas.calender_canvas .Roti_list button:hover {
  background-color: #AE8340;
  color: #ffff;
}

.offcanvas.calender_canvas .Roti_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 30px;
}


.roti_full .form-check label.form-check-label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #777391;
  font-family: 'Plus Jakarta Sans', sans-serif;
  padding-left: 5px;
}

.roti_full {
  display: flex;
  align-items: center
}


.Delect_roti img {
  width: 100%;
  max-width: 40px;
}

.Calnder_tab .roti_full .form-check .form-check-input:checked,
.fulltali .form-check .form-check-input:checked {
  background-color: #AE8340;
  border-color: #AE8340;
}



.Calnder_tab .roti_full .form-check {
  width: 100%;
  border: 1px solid #E7E6EE;
  border-radius: 12px;
  max-width: 123px;
  padding: 8px 35px;
  margin-bottom: 15px;
}


.Calnder_tab .roti_full .Form_box {
  margin-bottom: 20px;
}

.Calnder_tab .roti_full .form-check-input[type=radio] {
  border-radius: 50%;
  width: 1em;
  height: 1em;
  margin-top: 0.40em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, .25);
  appearance: none;
  -webkit-print-color-adjust: exact;
}


.Fateha_div .Form_box input {
  border: 1px solid #B1AFC0;
  border-radius: 8px;
  padding: 11px 10px;
}

.offcanvas.calender_canvas.offcanvas-end .offcanvas-body {
  height: auto;
  max-height: 77vh;
  min-height: 77vh;
  overflow-x: hidden;
  padding: 0;
}

.Dishes_canvas .offcanvas-body {
  padding-bottom: 60px;
}

.offcanvas.calender_canvas.offcanvas-end .Ingredients_title {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}

.fulltali label.form-check-label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #777391;
  font-family: 'Plus Jakarta Sans', sans-serif;
  padding-left: 5px;
}

.fulltali {
  display: flex;
  align-items: center;
}



.fulltali .form-check-input {
  padding: 10px 10px;
}

.fulltali .form-check {
  margin-right: 30px;
  margin-top: 10px;
}

body .form-check-input:focus {
  box-shadow: none !important;
}



.fulltali button.btn.thali_btn {
  padding: 8px 45px;
}


.add_dish_Add .Popup_heding {
  align-items: baseline;
}

.add_dish_Add .edit_from {
  margin-top: 25px;
  padding-top: 25px;
}

.Delect_roti {
  margin-bottom: 15px;
}

/* .rbc-time-content {
  display: none;
} */

/* .rbc-time-header {
  height: 100%;
} */


/* .rbc-event-label {
  display: none;
} */

.Select-clear-zone {
  display: none;
}

.rbc-time-view .rbc-allday-cell {
  display: none;
}


body .rbc-time-view .rbc-event {
  top: 0% !important;
  min-height: 50%;
  width: 100% !important;
  position: unset !important;
}

.rbc-day-slot .rbc-events-container {
  display: flex;
  flex-flow: column;
}

body .rbc-toolbar button:last-child img {
  transform: rotate(180deg);
}

.rbc-date-cell.rbc-now,
.rbc-header.rbc-today {
  background: #490b26 !important;
}

body .rbc-date-cell.rbc-now button.rbc-button-link,
.rbc-header.rbc-today button.rbc-button-link span {
  color: #fff !important;
}


.rbc-time-view .rbc-time-content {
  overflow-y: visible !important;
}


.rbc-event {
  background: transparent !important;
}

.rbc-events-container {
  background: #fff !important;
}

.rbc-time-header.rbc-overflowing {
  margin-right: 0 !important;
}

/* #edit_calender_popup .modal_body {
  overflow-y: scroll;
  height: 228px;
} */


#edit_calender_popup .modal_body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
  border-radius: 10px;
}

#edit_calender_popup .modal_body::-webkit-scrollbar {
  width: 2px;
  background-color: #F5F5F5;
}

#edit_calender_popup .modal_body::-webkit-scrollbar-thumb {
  background-color: #957137;
  border: 2px solid #957137;
}

button.page-link,
li.page-item.disabled,
button.page-link {
  background: transparent !important;
  color: #957137 !important;
  border-color: #957137 !important;

}


li.page-item.active button.page-link {
  color: #ffff !important;
  border: 1px solid #480b26;
}

.page-link:hover {
  z-index: 2;
  color: #fff !important;
  background-color: #4d0726 !important;
  border-color: #4d0726 !important;
}



ul.pagination {
  justify-content: center;
}

body .image-fit {
  width: 32px;
  object-fit: cover;
  border-radius: 5px;
  height: 32px;
}

li.page-item.active {
  background: #480b26;
  color: #fff;
}

li.page-item.disabled:first-child {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.page-link:focus {
  box-shadow: none;
}

th.active {
  /* background: #ddb7b7; */
  opacity: 0.7;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}


.Date_ragisterdata input {
  background-color: #FFFFFF;
  border: 1px solid #B1AFC0;
  border-radius: 8px;
  padding: 12px 16px;
  display: flex;
  width: 100%;
  max-width: 130px;
  margin: 0 9px;
}

.Date_ragisterdata input:focus-visible {
  outline: none;
  box-shadow: none;
}

li.page-item.disabled:last-child {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}


/* table th {
  vertical-align: middle;
} */

li.page-item button.page-link:focus {
  box-shadow: none !important;
}

.hover_img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  display: none;
}

.hover_img button {
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}

body .vendor_canvas .Img_upload:hover .hover_img {
  display: block;
  cursor: pointer;
}


.view_Registeruser .Form_box .css-1fdsijx-ValueContainer {
  padding: 0px 4px;
  width: 100%;

  min-width: 74px;
  border-radius: 40px;
  font-size: 10px;
}


ul.dishnameslist {
  list-style: none;
  padding-left: 0;
  background: #490b26;
}

ul.dishnameslist li span {

  color: #fff;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  /* color: #2E294E; */
  font-family: 'Plus Jakarta Sans', sans-serif;
  padding: 10px 10px;
}



.No_Inventory h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 200px;
  color: #2E294E;
  text-align: center;
}

li span.plenty {
  color: #428A55 !important;
  background: var(--brand-supporting-green-light, #F1F9F3);
}

li span.new {
  background: var(--black-background, #F9F9F9);

}


.Inventorydata li span {
  border-radius: 32px;
  padding: 5px 10px;
}


li span.nocolor {
  color: #B82214 !important;
  background: var(--brand-supporting-red-light, #FDEEED);
}


.onoffswitch {
  position: relative;
  width: 4em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid #FFFFFF;
  border-radius: 20px;
}

.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 12px;
  color: black;
  font-weight: bold;
  box-sizing: border-box;
}

.onoffswitch-inner:before {
  content: "";
  padding-left: 15px;
  background-color: #490b26;
  color: #FFFFFF;
}

.onoffswitch-inner:after {
  content: "";
  padding-right: 15px;
  background-color: gray;
  color: #FFFFFF;
  text-align: right;
}

.onoffswitch-switch {
  display: block;
  width: 24px;
  margin: 5px;
  background: #FFFFFF;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 28px;
  border: 2px solid #FFFFFF;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch {
  right: 0px;
}


label.onoffswitch-label {
  display: block !important;
}

.dish-viwe .calender_tabal ul.thali_menu_list li {
  margin: 10px 0px;
  padding: 0 0px;
}


button.page-link.pagebutton {
  width: 43px;
}

select.selectbyday {
  border: 1px solid #B1AFC0;
  transition: all 100ms;
  background-color: hsl(0, 0%, 100%);
  border-radius: 8px;
  min-height: 38px;
  box-sizing: border-box;
  padding: 7px 10px;
  width: 100%;
  min-width: 200px;
  position: relative;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(../img/down.png);
  background-repeat: no-repeat;
  background-position: 96%;
}

select.selectbyday option {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #2E294E;
}


body .csvbtn a {
  width: 100%;
  height: 100%;
  display: block;
  border: none;
}

body .csvbtn a:hover {
  background: transparent;
  border: none;
}

button.btn.thali_btn.csvbtn:hover a {
  border: none;
  background-color: transparent;
}

li.deletedthali {
  margin: 0 2px !important;
}



.thali_dish_table_section
{
  min-height: 655px;
}

/* new-css */

.thali_table_section,
.calender_table {
  min-height: 855px;
}

.thali_table_inventory_section,
.calender_table {
  min-height: 530px;
}

.thali_table_user_section,
.calender_table {
  min-height: 630px;
}

.thali_procurement_table_section {
  min-height: 520px;
}


/* Validations CSS */
.show_invalid_text {
  color: red;
  font-size: 14px;

}

.validation_valid {
  border-color: red !important;
}

.validation_valid .css-13cymwt-control {
  border-color: red !important;
}

button.btn.thali_btn.active {
  background: #957137;
  color: #ffff;
}




.singal_calendar {
  background-color: #FFFFFF;
  border: 1px solid #B1AFC0;
  border-radius: 8px;
  padding: 10px 16px;
  display: flex;
  width: 100%;
  max-width: 183px;
  flex-flow: row;
  align-items: center;
  outline: none;
}

.singal_calendar .react-datepicker__input-container {
  width: 100%;
  max-width: 111px;
}

.singal_calendar input {

  outline: none;
}

.Week_list.hover_remove:hover {
  box-shadow: none;
}


.lebal_pdf_download {
  text-align: end;
  padding: 20px 10px;
}

.lebal_pdf_download h1.title_heading {
  justify-content: right;
}

.lebal_pdf_download .pdf_btn button {
  border: none;
  width: 56px;
  border-radius: 16px;
  height: 56px;
  margin: 0 6px;
  outline: none;
  box-shadow: none;
}



td.today.off.ends.active.start-date.active.end-date.available,
td.off.ends.active.start-date.available,
td.off.ends.active.end-date.in-range.available {
  color: #fff;
}



.New_section .Calender_div input.form-control {
  min-width: 204px;
}


.css-b62m3t-container:focus-visible {
  box-shadow: none !important;
  outline: none !important;
}



div#edit_inventory {
  width: 100%;
  max-width: 700px;
  height: auto;
  /* max-height: 460px; */
  background: #FFFFFF !important;
  border: 1px solid #E7E6EE !important;
  box-shadow: 0px 4px 2px -2px rgba(27, 46, 94, 0.02);
  border-radius: 16px !important;
}


div#edit_inventory .Remove_from {
  max-width: 509px;
}


div#edit_inventory .Remove_from .Thali_page_btn_from {
  flex-flow: row;
  justify-content: flex-end;
  margin-top: 70px;
}


div#edit_inventory .Remove_from img {
  width: 100%;
  max-width: 100px;
}


.calender_tabal.newcalender ul.thali_menu_list li {
  margin: 0px 0px;
  padding: 5px 0;
}


label.repetitioncount {
  justify-content: space-between;
  display: flex;
}



span.Pending {
  color: #2B2200;
  background: #FFEC7F;
  padding: 10px;
  font-size: 14px !important;
  border-radius: 12px !important;
  font-weight: bold;
}


.AddStandardInventory .onoffswitch-inner:before,
.AddStandardInventory .onoffswitch-inner:after {
    height: 30px;
}

.AddStandardInventory label {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  display: flex;
  align-items: center;
  color: #2E294E;
  font-family: 'Plus Jakarta Sans', sans-serif;
}


.AddDishes .onoffswitch-inner:before,
.AddDishes .onoffswitch-inner:after {
    height: 30px;
}

.AddDishes label {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  width: "100%";
  display: block;
  align-items: right;
  color: #2E294E;
  font-family: 'Plus Jakarta Sans', sans-serif;
}


.AddUnRegisteredText label {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  display: flex;
  align-items: center;
  color: #2E294E;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.AddStandardInventory {
  display: flex;
  align-items: center;
}

.AddDishes {
  display: flex;
  align-items: left;
}



/* .custom-event.MIQAAT  {  
    background:  #AE8340;
    height: 100vh;
    padding: 10px 10px;
    margin: 0;
} */
/* 
.custom-event.save_draft{  
  background:  #383c23;
  height: 100vh;
  padding: 10px 10px;
  margin: 0;
} */

/* .custom-event.MIQAAT .event-title   ,.custom-event.save_draft  .event-title {
    color: #fff;
} */



.rbc-event {
  padding: 0 !important;
}

/* .custom-event.MIQAAT .Menu_test  ,.custom-event.save_draft .Menu_test{
    color: #ffff;
} */


/* .rbc-month-view .rbc-event .custom-event.MIQAAT ul.select_div.d-none  ,.rbc-month-view .rbc-event .custom-event.save_draft ul.select_div.d-none {
  display: block !important;
  color: #Fff;
  list-style: disc;
  margin-bottom: 0px;
} */


/* .custom-event.MIQAAT .event-title path ,.custom-event.save_draft .event-title path {
  fill: #fff;
} */


/* .custom-event.MIQAAT button.btn.event_btn  ,.custom-event.save_draft button.btn.event_btn{
  border-color: #fff;
  color: #fff;
} */

/* body .rbc-time-view .rbc-event {
  min-height: 100%;

} */


button.rbc-button-link {
  pointer-events: none;
}



img.img-firud.logimg {
  width: 100%;
  max-width: 140px;
  margin-right: 25px;
}

img.img-firud.logimg:last-child {
  max-width: 189px;
}

img.img-firud.log2img {
  width: 100%;
  max-width: 90px;
  margin-right: 15px;
}


img.img-firud.log2img:last-child {
  max-width: 190px;
}


/* tr.MIQAATTYPE {
  background: #957137;
} */

/* tr.save_draft_LIST {
  background: #383c23 !important;

} */


/* tr.MIQAATTYPE .event-title   ,tr.MIQAATTYPE  ul.thali_menu_list li    ,tr.MIQAATTYPE  ul.thali_menu_list p ,tr.save_draft_LIST .event-title   ,tr.save_draft_LIST  ul.thali_menu_list li    ,tr.save_draft_LIST  ul.thali_menu_list p{
  color: #fff;
} */
/* 
tr.MIQAATTYPE ul.thali_menu_list svg path  ,tr.save_draft_LIST ul.thali_menu_list svg path{
  fill: #fff;
} */


.rbc-day-slot .rbc-events-container {
  margin-right: 0px !important;
}


/* tr.MIQAATTYPE ul.thali_menu_list .dropdown-item svg path  ,tr.save_draft_LIST ul.thali_menu_list .dropdown-item svg path {
  fill: black;
} */

span.Published {
  color: #2B2200;
  background: #BDFFC7;
  padding: 10px;
  font-size: 14px !important;
  border-radius: 12px !important;
  font-weight: bold;
}



.Form_box.Totalcount input {
  border: 1px solid #AE8340;
  margin-top: 5px;
  margin-left: 10px;
  padding: 4px 4px;
  font-size: 14px;
  width: 100%;
  max-width: 70px;
  text-align: center;
}




/* .custom-event.save_draft {
  background: #383c23;
} */


.COlor_box h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #777391;
  font-family: 'Plus Jakarta Sans', sans-serif;
  display: flex;
  margin: 0 5px
}

.COlor_box h5 span {
  width: 25px !important;
  height: 25px !important;
  border-radius: 5px;
  margin: 0 5px;
  display: block;
}

.COlor_box {
  display: flex;
  justify-content: end;
  display: none;
}


.COlor_box h5:nth-child(1) span {

  background: #490b26 !important;
}

.COlor_box h5:nth-child(2) span {

  background: #AE8340 !important;
}

.COlor_box h5:nth-child(3) span {

  background: #383c23 !important;
}


span.Draft_thali {
  background:  #27714b;
  /* background: #AE8340; */
  border-radius: 5px;
  padding: 6px 10px;
  margin: 0 5px;
  color: #ffff !important;
  font-weight: 700 !important;
  font-size: 12px;
}


span.Draft_MIQAAT {
  /* background: #383c23; */
  background: #AE8340;
  border-radius: 5px;
  padding: 6px 10px;
  margin: 0 5px;
  color: #ffff !important;
  font-weight: 700 !important;
  font-size: 12px;
}

span.Draft_NIYAAZ {
  /* background: #383c23; */
  background: #40ae4b;
  border-radius: 5px;
  padding: 6px 10px;
  margin: 0 5px;
  color: #ffff !important;
  font-weight: 700 !important;
  font-size: 12px;
}

span.Draft_ORDERING {
  /* background: #383c23; */
  background:  #5cadd5;
  border-radius: 5px;
  padding: 6px 10px;
  margin: 0 5px;
  color: #ffff !important;
  font-weight: 700 !important;
  font-size: 12px;
}


ul.thali_menu_list.Standard {
  padding-bottom: 20px;
}


.Register_user .form-check-input:checked {
  background-color: #490b26;
  border-color: #490b26;
}


span.Draft_MIQAAT_event,
span.Draft_thali_event {
  /* background: #383c23; */
  background: #AE8340;
  border-radius: 5px;
  padding: 4px 5px;
  margin: 0 0px;
  color: #ffff !important;
  font-weight: 700 !important;
  font-size: 9px;
  margin-right: 2px;
  margin-top: 20px;
}

span.Draft_NIYAAZ_event,
span.Draft_thali_event {
  /* background: #383c23; */
  background: #40ae4b;
  border-radius: 5px;
  padding: 4px 5px;
  margin: 0 0px;
  color: #ffff !important;
  font-weight: 700 !important;
  font-size: 9px;
  margin-right: 2px;
  margin-top: 20px;
}


span.Draft_MIQAAT2_event,
span.Draft_thali_event {
  background: #AE8340;
  border-radius: 5px;
  padding: 4px 8px;
  color: #ffff !important;
  font-weight: 700 !important;
  font-size: 9px;
}

span.Draft_thali_event {
  background: #27714b;
}

span.Draft_ordering_event {
  background: #5cadd5;
  border-radius: 5px;
  padding: 4px 8px;
  color: #ffff !important;
  font-weight: 700 !important;
  font-size: 10px;
}

span.Draft_inactive_event {
  background: #d15b5b;
  border-radius: 5px;
  padding: 4px 8px;
  color: #ffff !important;
  font-weight: 700 !important;
  font-size: 10px;
}

span.Draft_notes_event {
  background: #5b8bd3;
  border-radius: 5px;
  padding: 4px 8px;
  color: #ffff !important;
  font-weight: 700 !important;
  font-size: 10px;
}

span.Draft_ordering2_event {
  background: #5cadd5;
  border-radius: 5px;
  padding: 4px 8px;
  color: #ffff !important;
  font-weight: 700 !important;
  font-size: 9px
}


h1.title_heading span.Pending,
h1.title_heading span.Published {
  font-size: 14px !important;
  border-radius: 12px !important;
  line-height: 16px !important;
  padding: 10px;
  text-transform: none;
}


.labal_table.p-3 {
  display: none;
}


.box ul.list-group li {
  /* border-color: #000; */
  width: 100%;
  max-width: 261px;

}


.box ul.list-group li.tital {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #777391;
}

ul.labal-sheet {
  padding-left: 0;
  /* display: none; */
  list-style: none;
}

.box {
  margin-bottom: 40px;
}

ul.labal-sheet li {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 13px;
  color: #2E294E;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  text-transform: none;
  cursor: pointer;
  display: block;
  text-align: center;
}

ul.labal-sheet {
  background-color: #ffff;
  /* border: 1px solid #2E294E; */
  padding: 20px 18px;
  margin-bottom: 0;
  width: 100%;
  max-width: 250px;
}

.table-data {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
}

ul.labal-sheet li .red {
  color: red;
}

ul.labal-sheet .blue {
  color: blue;
}


ul.labal-sheet span.black {
  color: #000;
}

ul.labal-sheet span.green {
  color: #448444;
}

ul.labal-sheet span.blue-high {
  color: #0c0ca3;
  font-weight: bold;
}

.thali_table_section {
  position: relative;
}

.Loading-img {
  position: absolute;
  width: 20px;
  top: 22%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.Loading-img img {
  width: 63px;
}


.Thali_page_btn_from.btn-div {
  flex-flow: row;
  justify-content: end;
}


.Thali_page_btn_from.btn-div button {
  margin: 0 4px;
}

.thali_table_section.new-table {
  min-height: 100%;
}

.event-img {
  width: 80px;
}


.popup_modal.event-div .Remove_from {
  max-width: 100%;
}

.popup_modal.event-div button {
  float: right;
  margin-top: -19px;
}

.popup_modal.event-div .Registers_tabal {
  height: 100%;

}

.popup_modal.event-div ul.thali_menu_list li {
  white-space: nowrap;
}

div#Event_popup {
  /* width: 100%;
  height: 100vh; */
  width: 50%;
  height: 50%;
  border-radius: 0 !important;
  padding: 0 !important;
  position: relative;
}



.popup_modal.dish-viwe button {
  position: absolute;
  color: #fff !important;
  top: 4%;
  right: 1%;
  transform: translate(-50%, -50%);
}

aside.ps-sidebar-root.css-uerakn {
  height: 100%;
}

.del_all {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.remove-icon-thaali {
  width: 37px;
}


/* new page  */

.Thaali-myBg .card {
  width:100%;
  height:100%;
  background:#D3E3FD;
}


.Thaali-reg .card {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-flow: wrap;
  padding: 20px 20px;
  margin-bottom: 20px;
  background:'#D3E3FD';
}

.Thaali-reg .card .Calendar_card {
  margin: 20px 0;
}


h5.thali-heding {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 0px;
  color: #2E294E;
  padding: 0.5rem 0.3rem;
  display: flex;
  align-items: center;
  text-transform: none;
  cursor: pointer;
}

h5.thali-user-heding {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 0px;
  color: #2E294E;
  padding: 0.5rem 0.3rem;
  display: flex;
  align-items: center;
  text-transform: none;
  cursor: pointer;
}

/* h5.menu-reg-heding {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 0px;
  color: #2E294E;
  padding: 0.5rem 0.5rem;
  display: flex;
  align-items: center;
  text-transform: none;
  cursor: pointer;
} */

.registration-heding {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: italic;
  font-weight: 500;
  font-size: 13px;
  margin-top: 2px;
  color: #2E294E;
  padding: 0.6rem 0.6rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* .notes-div {
  border-width: 1;
  background-color: #FBE2BA;
} */

.notes-heding {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  background-color: #FBE2BA;
  border-radius: 10px;
  font-weight: 500;
  font-size: 13px;
  margin-top: 2px;
  color: #2E294E;
  padding: 0.6rem 0.6rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  max-width: 350px;
}

.miqaat-thaali-heding {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  background-color: #5C5EC1;
  border-radius: 10px;
  font-weight: 600;
  font-size: 13px;
  margin-top: 2px;
  color: #ffffff;
  padding: 0.6rem 0.6rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  max-width: 350px;
}

.miqaat-zone-heding {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  background-color: #F2F2F2;
  border-radius: 4px;
  border-width: 2px;
  border-color: #5C5EC1;
  font-size: 13px;
  margin-top: 2px;
  color: rgb(82, 79, 79);
  padding: 0.6rem 0.6rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  max-width: 350px;
}



.menu-div {
  width: 100%;
  max-width: 10%;
}

.reg-menu-div,
.spacediv {
  max-width: 150px;
  width: 100%;
}

.thali-sched-caterer-div {
  max-width: 245px;
  width: 100%;
}


.spacediv button.btn.thali_btn {

  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  padding: 6px 10px;
}

.Thaali-reg .card .form-check.form-switch {
  padding-left: 2.5rem;
}


.flatpickr-calendar.hasWeeks.animate.open.arrowTop.arrowLeft {
  margin-top: 2px;
}

.thili_div.container-fluid a {
  opacity: 1;
}

.card-flex .card {
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
  /* align-items: center; */
}

body .card-flex .card {

  padding: 10px 10px;
}



.manu-card .Calendar_card {
  margin: 0 auto;
}

@media (max-width: 1440px) {

  .reg-menu-div,
  .spacediv {
    width: 100%;
    max-width: 150px;
  }

  .thali-sched-caterer-div
  {
    max-width: 245px;
    width: 100%;
  }

  .spacediv button.btn.thali_btn {
    font-size: 11px;
    line-height: 24px;
    padding: 6px 16px;

  }
}


@media (max-width: 1366px) {

  .reg-menu-div,
  .spacediv {
    width: 100%;
    max-width: 100px;
  }

  .thali-sched-caterer-div
  {
    max-width: 245px;
    width: 100%;
  }

  h5.thali-heding {
    font-size: 14px;
  }

  h5.thali-user-heding {
    font-size: 9px;
  }

  .spacediv button.btn.thali_btn {
    font-size: 11px;
    line-height: 18px;
  }

}


@media (max-width: 1240px) {

  .Thaali-myBg .card {
    width:100%;
    height:100%;
    background:red;
  }


  .Thaali-reg .card {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-flow: row;
    padding: 20px 20px;
    margin-bottom: 20px;
    flex-flow: wrap;
  }

}


@media (max-width: 996px) {
  main.Main_btn {
    display: none;
  }

  .reg-menu-div,
  .spacediv {
    width: unset;
    max-width: unset;
  }

  .thali-sched-caterer-div
  {
    max-width: 245px;
    width: 100%;
  }

  .Registers_tabal span.thali_table {
    font-size: 10px;
  }

  .Calendar_card p {
    font-size: 10px;
  }

  button.btn.thali_btn {
    padding: 12px 10px;
  }


}

@media (max-width: 767px) {

  .Popup_heding h1 {
    font-size: 14px;
  }

  .Popup_heding p {
    font-size: 10px;
    line-height: 14px;
  }

  .Popup_heding img {
    width: 32px;
    height: 32px;
  }

  .popup_modal .d-flex {
    align-items: flex-start !important;
  }

  .thili_div {
    justify-content: flex-start;
    flex-flow: wrap;
  }

  .New_section {
    justify-content: space-between;
    width: 100%;
    margin-top: 14px;
    flex-wrap: wrap;
  }

  .Date_ragisterdata input {
    margin-left: 0;
    padding: 8px 12px;
  }

  .card-flex .card {
    justify-content: space-between;
    /* align-items: center; */
    padding: 0 10px;
  }

  body .manu-card ul.thali_menu_list li {
    margin: 6px 0px;
  }

  body .header-content-tooggle {
    height: 100vh;
    min-height: 1122px;
    max-height: 819px;
  }

  h1.title_heading span.Date_list {
    font-size: 12px;
    margin-left: 10px;
    padding: 0px 8px;
  }

  body .spacemargin ul.thali_menu_list li {
    margin: 6px 0;
  }

  .manu-card {
    margin-top: 10px;
  }

  div#edit_register .popup_modal {
    margin-bottom: 10px;
  }

  body .rbc-toolbar {
    justify-content: center !important;

  }

  .COlor_box h5 {
    font-size: 12px;
    align-items: center;
  }

  .calender_titile h1 {
    font-size: 18px;
    line-height: 26px;
  }

  .New_section .Calender_div input.form-control {
    text-align: center;
  }

  .popup_modal.event-div .Registers_tabal {
    margin-bottom: 40px;
  }

  .thali_table_section {
    margin-bottom: 15px;
  }

  .AddStandardInventory {
    width: 100%;
    justify-content: inherit;
  }

  .AddDishes {
    width: 200%;
    justify-content: right;
    margin-right: 1200;
  }


  .New_section button {
    margin-bottom: 10px;
  }

  ul.pagination {
    flex-flow: wrap;
  }

  .No_Inventory h1 {
    font-size: 18px;
    line-height: 32px;
  }

  .Thali_page_btn {
    width: 100%;
  }

  .Back-page {
    justify-content: space-between;
    width: 100%;
  }

  .Thali_page_btn.d-flex {
    flex-flow: wrap;
    justify-content: space-between;
  }

  body h1.title_heading {

    flex-flow: wrap;
    justify-content: space-around;
  }

  .dropdown.action_btn a.dropdown-item {
    padding-left: 0;
  }
}

img.logo-res {
  width: 100%;
  max-width: 250px;
}



.Date_ragisterdata input {
  text-align: center;

}



@media (max-width: 1300px) {
  .navbar-expand-lg .navbar-toggler {
    display: block !important;
  }

  a.navbar-brand {
    display: none;
  }

  body .header-content-tooggle {
    display: block;
  }
}




.Date_ragisterdata.calender_view img {
  width: 75%;
  max-width: 50px;
  /* position: relative; */
  height: 50px;
}

.calender-div {
  min-width: 50px;
  max-width: 50px;
  position: absolute;
  height: 50px;
  top: 0;
  left: -15px;
  transform: translate(-0%, -0%);
  /* opacity: 0; */
  z-index: 9;
}

.calender-div input {
  opacity: 0;
}

.Date_ragisterdata.calender_view {
  position: relative;
  margin-right: 1px;
}


/* .Thali-count-by-portion ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
} */



.thali_table_section.TotalIngredientstable ul.totalspan li span {
  background-color: #51AA68;
  width: 10px !important;
  height: 10px !important;
  padding: 0px 5px;
  border-radius: 2px;
  font-size: 8px;
  margin-right: 10px;
}

.thali_table_section.TotalIngredientstable ul.totalspan li:nth-child(2) span {

  background-color: #6E2FFD
}

.thali_table_section.TotalIngredientstable ul.totalspan li:nth-child(3) span {

  background-color: #EB5548
}

.thali_table_section.TotalIngredientstable ul.totalspan li:nth-child(4) span {

  background-color: #3051C4
}

/* .thali_table_section.TotalIngredientstable h1 {
  color: #2E294E;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;

} */

/* 
.Thali-count-by-portion {
  padding-top: 25px;
  padding-bottom: 0px;
  display: flex;
  justify-content: space-between;
} */

.thali_table_section.TotalIngredientstable {
  min-height: 100%;
}


.thali_table_section.TotalIngredientstable table.table th {
  width: 100%;
  max-width: 20%;
}



.thali-checkbox {
  width: 100%;
  max-width: 131px;
}