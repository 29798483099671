/* sidebarmenu */

.sider_heading p {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 12px;
    letter-spacing: 1px;
    color: #777391;
    /* margin-bottom: 0; */
    padding-right: 20px;
    padding-left: 0px;
    margin: 0 14px;
  }
  
  
  .sider_heading li span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #777391;
    font-family: 'Plus Jakarta Sans', sans-serif;
    display: flex;
    align-items: center;
  }
  
  .sider_heading,
  .main_section {
    margin-top: 15px;
  }
  
  .sider_heading li a svg {
    margin-right: 10px;
  }
  
  main.Main_btn button {
    background: transparent;
    border: none;
    margin: 0 14px;
  
  }
  
  main.Main_btn button {
    color: #777391;
  }
  
  main.Main_btn {
    /* bottom: 0%;
    z-index: 999;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute; */
    text-align: center;
  }
  
  
  .css-uerakn.ps-collapsed .sider_heading p {
    display: none;
  }
  
 body  .sider_heading li a {
    padding: 26px 0;
    margin: 6px 10px;
    /* background: #F9F6F0; */
    border: 1px solid transparent;
    margin-bottom: 0;
    padding-right: 10px  !important;
    padding-left: 10px !important;
  }
  
  .sider_bg {
    background: rgba(249, 249, 249, 0.9);
    border-right: 1px solid #E7E6EE;
    backdrop-filter: blur(15px);
  }
  
  .css-uerakn.ps-collapsed .sider_heading li a {
    margin: 2px 10px;
  
  }
  
  .sider_heading li a:hover {
    border: 1px solid #AE8340;
    border-radius: 8px;
  }
  
  .sider_heading li a:hover svg path {
  
    fill: #AE8340;
  }
  
  .sider_heading li a:hover span {
    color: #957137;
    font-weight: 600;
  }
  
  .css-uerakn.ps-collapsed .hide_Text {
    display: none;
  }
  
  
  .css-uerakn.ps-collapsed .sider_heading li a svg {
    margin-right: 0;
  }
  
  .css-uerakn.ps-collapsed .sider_heading li span {
    justify-content: center;
  }
  
  .css-uerakn.ps-collapsed .sider_heading li a {
    padding: 0 10px;
  }
  
  /* .side_div_menu {
    max-height: calc(100vh - 75px) !important;
    min-height: calc(100vh - 75px) !important;
  
  } */
  
.css-uerakn .ps-sidebar-container {
    background-color: rgba(249, 249, 249, 0.9);
}

/* media query */
@media (max-width: 1300px) {

body .sider_bg{
  display: none
}

}
